<template>
  <div>
    <b-row cols="1">
      <b-col>
        <b-card>
          <h3>
            เอกสารยืนยันตัวตน <span v-if="accountDetails">ร้าน {{ accountDetails.shop_name }}</span>
          </h3>
          <div v-if="loading || settingInfoLoading" class="mt-2">
            <b-skeleton width="85%" />
            <b-skeleton width="55%" />
            <b-skeleton width="70%" />
          </div>
          <div v-else-if="accountDetails">
            <b-row>
              <b-col sm="12" md="6">
                <p class="mb-50">
                  สถานะ :
                  <b-badge class="ml-50" :variant="statusSettingInfoVariant[accountDetails.verify_setting_info.status]">
                    {{ statusSettingInfoText[accountDetails.verify_setting_info.status] }}
                  </b-badge>
                </p>
              </b-col>
              <b-col sm="12" md="6">
                <p>หมายเหตุ : {{ accountDetails.verify_setting_info.note || '-' }}</p>
              </b-col>
            </b-row>
            <b-row v-if="accountDetails.verify_setting_info.status !== 'Wait Document'">
              <b-col sm="12" class="mb-50 mt-50">
                <h5 class="mb-0">รายละเอียดทั่วไป</h5>
                <hr class="mt-50" />
              </b-col>
              <b-col sm="12" md="6">
                <table class="w-100">
                  <tr v-for="info in listSettingInfoSection1" :key="info.key">
                    <th class="pb-50 w-50">
                      <feather-icon :icon="info.icon" class="mr-75" />
                      <span class="font-weight-bold">{{ info.title }}</span>
                    </th>
                    <td v-if="info.renderDetail" class="pb-50">
                      {{ info.renderDetail(accountDetails) || accountDetails[info.key] }}
                    </td>
                    <td v-else class="pb-50">
                      {{ accountDetails[info.key] }}
                    </td>
                  </tr>
                </table>
              </b-col>
              <b-col sm="12" md="6">
                <table class="w-100">
                  <tr v-for="info in listSettingInfoSection2" :key="info.key">
                    <th class="pb-50 w-50">
                      <feather-icon :icon="info.icon" class="mr-75" />
                      <span class="font-weight-bold">{{ info.title }}</span>
                    </th>
                    <td v-if="info.renderDetail" class="pb-50">
                      {{ info.renderDetail(accountDetails) || accountDetails[info.key] }}
                    </td>
                    <td v-else class="pb-50">
                      {{ accountDetails[info.key] }}
                    </td>
                  </tr>
                </table>
              </b-col>
              <b-col sm="12" class="mb-1 mt-2">
                <h5 class="mb-0">รายละเอียดรูปหลักฐาน</h5>
                <hr class="mt-50" />
              </b-col>
              <b-col sm="12" md="4">
                <p class="text-md-center">
                  หน้าสมุดธนาคาร
                </p>
                <div class="preview_image d-flex flex-column align-items-center my-2">
                  <b-img
                    :src="accountDetails.image_bank_info"
                    height="100px"
                    width="auto"
                    class="cursor-zoom"
                    @click="gZoomImage"
                  />
                </div>
              </b-col>
              <b-col sm="12" md="4">
                <p class="text-md-center">
                  สำเนาบัตรประชาชน
                </p>
                <div class="d-flex flex-column align-items-center my-2">
                  <b-img
                    :src="accountDetails.image_card_info"
                    height="100px"
                    width="auto"
                    class="cursor-zoom"
                    @click="gZoomImage"
                  />
                </div>
              </b-col>
              <b-col sm="12" md="4">
                <p class="text-md-center">
                  รูปคู่บัตรประชาชน
                </p>
                <div class="d-flex flex-column align-items-center my-2">
                  <b-img
                    :src="accountDetails.image_card_and_user_info"
                    height="100px"
                    width="auto"
                    class="cursor-zoom"
                    @click="gZoomImage"
                  />
                </div>
              </b-col>
              <b-col class="d-flex justify-content-sm-end flex-column flex-sm-row mt-2">
                <b-button
                  v-if="accountDetails.verify_setting_info.status !== 'Approval'"
                  variant="success"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="mb-50 mb-sm-0 mr-0 mr-sm-50"
                  @click="confirmAccountDetails('settingInfo')"
                  >อนุมัติ</b-button
                >
                <b-button
                  v-if="accountDetails.verify_setting_info.status !== 'Reject'"
                  variant="danger"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="openModalReject('settingInfo')"
                  >ไม่อนุมัติ</b-button
                >
              </b-col>
            </b-row>
          </div>
          <div v-else class="text-center my-2">
            ไม่พบข้อมูล
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h3>เอกสารสัญญา</h3>
          <div v-if="loading || agreementInfoLoading" class="mt-2">
            <b-skeleton width="85%" />
            <b-skeleton width="55%" />
            <b-skeleton width="70%" />
          </div>
          <div v-else-if="accountDetails">
            <b-row>
              <b-col sm="12" md="6">
                <p class="mb-50">
                  สถานะ :
                  <b-badge
                    class="ml-50"
                    :variant="statusAgreementInfoVariant[accountDetails.verify_agreement_info.status]"
                  >
                    {{ statusAgreementInfoText[accountDetails.verify_agreement_info.status] }}
                  </b-badge>
                </p>
              </b-col>

              <!-- contract_document_url -->
              <b-col sm="12" md="6">
                <p>หมายเหตุ : {{ accountDetails.verify_agreement_info.note || '-' }}</p>
              </b-col>
            </b-row>

            <div v-if="accountDetails.verify_agreement_info.status !== 'Pending'">
              <a class="mb-0 d-flex align-items-center justify-content-center my-2" :href="cPdfLink" target="_blank">
                <b-img :src="require('@/assets/images/icons/pdf.png')" height="auto" width="40" class="mr-1" />
                <span class="align-bottom">เอกสารสัญญา.pdf</span>
              </a>

              <div class="d-flex justify-content-sm-end flex-column flex-sm-row mt-2">
                <b-button
                  v-if="accountDetails.verify_agreement_info.status !== 'Approval'"
                  variant="success"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="mb-50 mb-sm-0 mr-0 mr-sm-50"
                  @click="confirmAccountDetails('agreementInfo')"
                  >อนุมัติ</b-button
                >
                <b-button
                  v-if="accountDetails.verify_agreement_info.status !== 'Reject'"
                  variant="danger"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="openModalReject('agreementInfo')"
                  >ไม่อนุมัติ</b-button
                >
              </div>
            </div>
          </div>
          <div v-else class="text-center my-2">
            ไม่พบข้อมูล
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="refModalReject"
      :title="modalModel.title"
      ok-title="ยืนยัน"
      cancel-title="ยกเลิก"
      @ok="submitReject"
      @cancel="clearModalModel"
    >
      <p>โปรดระบุเหตุผลที่ไม่อนุมัติ</p>
      <b-form-textarea v-model="modalModel.note" />
    </b-modal>
    <!-- <pre>{{ accountDetails }}</pre> -->
  </div>
</template>

<script>
import mediumZoom from 'medium-zoom'

export default {
  props: {
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modalModel: {
        title: '',
        note: '',
        key: '',
      },
      accountDetails: null,
      loading: false,
      settingInfoLoading: false,
      agreementInfoLoading: false,
      listSettingInfoSection1: [
        {
          key: 'account',
          title: 'ชื่อ-นามสกุล',
          type: 'string',
          icon: 'UserIcon',
        },
        {
          key: 'phone_number',
          title: 'เบอร์โทรศัพท์',
          type: 'string',
          icon: 'PhoneIcon',
        },
        // {
        //   key: 'temp1',
        //   title: 'อีเมล',
        //   type: 'string',
        //   icon: 'CircleIcon',
        // },
        {
          key: 'pid',
          title: 'รหัสบัตรประชาชน',
          type: 'string',
          icon: 'LockIcon',
        },
        {
          key: 'bank_type',
          title: 'ชื่อธนาคาร',
          type: 'string',
          icon: 'CircleIcon',
        },
        {
          key: 'major',
          title: 'สาขาธนาคาร',
          type: 'string',
          icon: 'CircleIcon',
        },
        {
          key: 'bank_no',
          title: 'เลขที่บัญชี',
          type: 'string',
          icon: 'CircleIcon',
        },
      ],
      listSettingInfoSection2: [
        {
          key: 'address',
          title: 'ที่อยู่',
          type: 'string',
          icon: 'CircleIcon',
        },
        {
          key: 'address_json.subdistrict',
          title: 'ตำบล/แขวง',
          renderDetail: obj => obj.address_json.subdistrict,
          type: 'string',
          icon: 'CircleIcon',
        },
        {
          key: 'address_json.district',
          title: 'อำเภอ/เขต',
          renderDetail: obj => obj.address_json.district,
          type: 'string',
          icon: 'CircleIcon',
        },
        {
          key: 'address_json.province',
          title: 'จังหวัด',
          renderDetail: obj => obj.address_json.province,
          type: 'string',
          icon: 'CircleIcon',
        },
        {
          key: 'address_json.zipcode',
          title: 'รหัสไปรษณีย์',
          renderDetail: obj => obj.address_json.zipcode,
          type: 'string',
          icon: 'CircleIcon',
        },
      ],
      statusSettingInfoText: {
        'Wait Document': 'รอเอกสารยืนยันตัวตนจาก partner',
        Submit: 'ตรวจสอบเอกสารยืนยันตัวตน',
        Reject: 'ไม่อนุมัติ',
        Approval: 'อนุมัติ',
      },
      statusSettingInfoVariant: {
        'Wait Document': 'secondary',
        Submit: 'secondary',
        Reject: 'danger',
        Approval: 'success',
      },
      statusAgreementInfoText: {
        Pending: 'รอเอกสารยืนยันตัวตนอนุมัติ',
        Approval: 'อนุมัติ',
        Reject: 'ไม่อนุมัติ',
        'Create document contract': 'รอครีเอเตอร์เซ็นเอกสารสัญญา',
        'Pending document contract': 'รอแอดมินอนุมัติเอกสารสัญญา',
      },
      statusAgreementInfoVariant: {
        Pending: 'secondary',
        Reject: 'danger',
        Approval: 'success',
        'Create document contract': 'secondary',
        'Pending document contract': 'secondary',
      },
    }
  },
  computed: {
    cPdfLink() {
      return this.accountDetails?.contract_document_url || null
    },
  },
  async created() {
    this.loading = true
    await this.getAccountDetailsById(this.userId)
    this.loading = false
  },
  methods: {
    clearModalModel() {
      this.modalModel = {
        title: '',
        note: '',
        key: '',
      }
    },
    async getAccountDetailsById(userId) {
      const resp = await this.api.get(`api/admin/partners/bankInfo/${userId}`).catch(() => null)
      // console.log('getAccountDetails', resp)
      if (resp) {
        this.accountDetails = { ...resp }
      }
    },
    openModalReject(key) {
      if (!key) return
      const format = {
        settingInfo: 'ไม่อนุมัติเอกสารยืนยันตัวตน',
        agreementInfo: 'ไม่อนุมัติเอกสารสัญญา',
      }
      this.modalModel.title = format[key]
      this.modalModel.key = key
      this.modalModel.note = ''

      this.$refs.refModalReject.show()
    },
    async submitReject() {
      const { key, note } = this.modalModel
      const formatUrl = {
        settingInfo: 'api/admin/partners/confirm-bank-account-data',
        agreementInfo: 'api/admin/partners/confirm-document-mail-data',
      }

      const formatLoading = {
        settingInfo: 'settingInfoLoading',
        agreementInfo: 'agreementInfoLoading',
      }

      this[formatLoading[key]] = true

      const resp = await this.api
        .post(formatUrl[key], { status: 'Reject', note, user_id: this.userId })
        .catch(() => null)
      // console.log('submitReject', resp)

      if (!resp) {
        this.gDisplayToast('พบข้อผิดพลาด', 'ไม่สามารถดำเนินการต่อได้ โปรดติดต่อผู้ดูแลระบบ', 'danger')
      } else {
        // await this.getAccountDetailsById(this.userId)
        this.gDisplayToast('ดำเนินการสำเร็จ')
        this.accountDetails = { ...resp }
      }

      this[formatLoading[key]] = false
    },
    async confirmAccountDetails(key) {
      if (!key) return
      const formatConfirm = {
        settingInfo: 'อนุมัติเอกสารยืนยันตัวตน',
        agreementInfo: 'อนุมัติเอกสารสัญญา',
      }

      const checkConfirm = await this.gCheckConfirmV1(formatConfirm[key])

      if (!checkConfirm) return

      const formatUrl = {
        settingInfo: 'api/admin/partners/confirm-bank-account-data',
        agreementInfo: 'api/admin/partners/confirm-document-mail-data',
      }

      const formatLoading = {
        settingInfo: 'settingInfoLoading',
        agreementInfo: 'agreementInfoLoading',
      }
      const url = formatUrl[key]

      this[formatLoading[key]] = true

      const resp = await this.api
        .post(url, {
          status: 'Approval',
          user_id: this.userId,
        })
        .catch(() => null)
      // console.log('confirmAccountDetails', resp)
      if (!resp) {
        this.gDisplayToast('พบข้อผิดพลาด', 'ไม่สามารถดำเนินการต่อได้ โปรดติดต่อผู้ดูแลระบบ', 'danger')
      } else {
        // await this.getAccountDetailsById(this.userId)
        this.gDisplayToast('ดำเนินการสำเร็จ')
        this.accountDetails = { ...resp }
      }

      this[formatLoading[key]] = false
    },
    zoomImage(ev) {
      const zoom = mediumZoom({ background: '#191f32', margin: 48 })
      zoom.attach(ev.target)
      zoom.on('closed', () => zoom.detach(), { once: true })
    },
  },
}
</script>

<style lang="scss" scoped>
.cursor-zoom {
  cursor: zoom-in;
}
</style>
